import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AppGlobals } from 'src/app/app.globals';
import { environment } from 'src/environments/environment';

export enum BaseRequestMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

@Injectable()
export class BaseService {
    private _httpClient: HttpClient;
    private _configurations: any = {};
    private _defaultHeaders: Object;
    private _services: any;

    public token: string;

    constructor(protected httpClient: HttpClient) {
        this._configurations = AppGlobals.getAppConfigurations();
        this._httpClient = httpClient;
        this._services = this._configurations.services;
        this._defaultHeaders = this._services.defaultHeaders;
    }

    callService<T>(
        method: BaseRequestMethod,
        serviceName: string,
        token?: string,
        data?: any,
        contentType = 'application/json',
        host: any = null,
        responseType = null
    ) {
        let contextService = this;
        let serviceHost = contextService.getHost(host);
        let url = serviceHost + serviceName;
        let body = { ...contextService._services.requestBody };
        let headers = {
            Accept: 'application/json',
            //'Content-Type': contentType,
            Authorization: 'Bearer ' + token,
        };

        if (data != null) {
            body = data;
        }

        let handlerService = null;

        Object.keys(contextService._defaultHeaders).forEach((header) => {
            headers[header] = contextService._defaultHeaders[header];
        });

        switch (method) {
            case BaseRequestMethod.GET:
                handlerService = contextService._httpClient.get<T>(url, {
                    headers: new HttpHeaders(headers),
                    responseType: responseType as 'json',
                });
                break;
            case BaseRequestMethod.POST:
                handlerService = contextService._httpClient.post<T>(url, body, {
                    headers: new HttpHeaders(headers),
                    responseType: responseType as 'json',
                });
                break;
            case BaseRequestMethod.PUT:
                handlerService = contextService._httpClient.put<T>(url, body, {
                    headers: new HttpHeaders(headers),
                });
                break;
            case BaseRequestMethod.PATCH:
                handlerService = contextService._httpClient.patch<T>(
                    url,
                    body,
                    {
                        headers: new HttpHeaders(headers),
                    }
                );
                break;
            case BaseRequestMethod.DELETE:
                handlerService = contextService._httpClient.request<T>(
                    'delete',
                    url,
                    {
                        headers: new HttpHeaders(headers),
                        body: body,
                    }
                );
                break;
        }

        return handlerService;
    }
    callServiceDownload<T>(
        method: BaseRequestMethod,
        serviceName: string,
        token?: string,
        data?: any,
        contentType = 'application/json',
        host: any = null,
        responseType = 'blob'
    ) {
        let contextService = this;
        let serviceHost = contextService.getHost(host);
        let url = serviceHost + serviceName;
        let body = { ...contextService._services.requestBody };
        let headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        };

        if (data != null) {
            body = data;
        }

        let handlerService = null;

        Object.keys(contextService._defaultHeaders).forEach((header) => {
            headers[header] = contextService._defaultHeaders[header];
        });

        switch (method) {
            case BaseRequestMethod.GET:
                handlerService = contextService._httpClient.get(url, {
                    headers: new HttpHeaders(headers),
                    responseType: 'blob',
                    observe: 'response',
                });
                break;
            case BaseRequestMethod.POST:
                handlerService = contextService._httpClient.post(url, body, {
                    headers: new HttpHeaders(headers),
                    responseType: 'blob',
                    observe: 'response',
                });
                break;
        }

        return handlerService;
    }

    private getHost(name: string): string {
        let contextService = this;
        let hosts = contextService._services.hosts;

        if (name == null || name == undefined) {
            return hosts[contextService._services.defaultHost];
        }

        let host = hosts[name]
            ? hosts[name]
            : hosts[contextService._services.defaultHost];

        return host;
    }
}
