import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './modules/auth/auth.service';
import { AuthApplicationRequest } from './modules/auth/models/request/auth';
import { LoaderService } from './modules/layout/spinner/spinner.service';
import { UrlRequest } from './modules/auth/models/request/url-request';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { Subject, filter, takeUntil } from 'rxjs';

enum RedirectApplication {
    MainMenu = "",
    Sms_buy = "sms",
    Orislineacademy = "academy"
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    isUserLoggedIn: boolean = false;
    userName?: string = '';
    private readonly _destroying$ = new Subject<void>();
    loginDisplay = false;

    menuMode = 'horizontal';

    layout = 'blue';

    theme = 'blue';

    ripple = false;

    colorScheme = 'light'

    showAllisone = false;

    constructor(
        private primengConfig: PrimeNGConfig,
        private router: Router,
        private authService: AuthService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalBroadcastService: MsalBroadcastService,
        private msalService: MsalService,
        private loaderService: LoaderService) {

    }


    ngOnInit(): void {
        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.setLoginDisplay();
            });
    }

    setLoginDisplay() {
        let accounts = this.msalService.instance.getAllAccounts()
        this.loginDisplay = accounts.length > 0;
    }
    getShowAllisone() {
        return this.showAllisone;
    }
}
