import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './modules/layout/app.layout.component';
import { AuthGuard } from './modules/auth/guards/auth-guard';
import { MsalGuard } from '@azure/msal-angular';

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
};

const routes: Routes = [
    {
        path: '',
        component: AppLayoutComponent,
        children: [
            {
                path: 'home',
                canActivate: [MsalGuard],
                loadChildren: () =>
                    import('./modules/home/home.module').then(
                        (m) => m.HomeModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/login/login.module').then(
                        (m) => m.LoginModule
                    ),
            },
            {
                path: 'request',
                canActivate: [MsalGuard],
                loadChildren: () =>
                    import('./modules/request/request.module').then(
                        (m) => m.RequestModule
                    ),
            },
        ],
    },
    {
        path: 'notfound',
        loadChildren: () =>
            import('./modules/demo/components/notfound/notfound.module').then(
                (m) => m.NotfoundModule
            ),
    },
    { path: '**', redirectTo: '/notfound' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
