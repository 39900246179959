import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Observable } from 'rxjs';
import { BaseRequestMethod, BaseService } from 'src/app/core/base/base.service';

@Injectable()
export class RequestService {
    constructor(private http: HttpClient, private baseservice: BaseService) {}

    getConfig(token: string): Observable<any> {
        return this.baseservice.callService<any>(
            BaseRequestMethod.GET,
            'refund/config/',
            token,
            null,
            'application/json',
            'backend'
        );
    }

    createRequest(token: string, refund: any): Observable<any> {
        return this.baseservice.callService<any>(
            BaseRequestMethod.POST,
            'refund/new',
            token,
            refund,
            'multipart/form-data',
            'backend'
        );
    }
}
