<div class="layout-menu-wrapper" [ngClass]="{'layout-sidebar-active': appLayout.sidebarActive}"
    (click)="appLayout.onSidebarClick($event)" (mouseover)="appLayout.onSidebarMouseOver($event)"
    (mouseleave)="appLayout.onSidebarMouseLeave($event)">
    <div class="menu-logo" *ngIf="!appLayout.isHorizontal() || appLayout.isMobile()">

        <a [routerLink]="['/']" class="logo">
            <img [src]="'assets/layout/images/orisline-logo.png'" />
        </a>
    </div>

    <div class="layout-menu-container">
        <ul class="layout-menu">
            <ng-container *ngFor="let item of model; let i = index;">
                <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
                <li *ngIf="item.separator" class="menu-separator"></li>
            </ng-container>
        </ul>
    </div>

    <app-inlinemenu></app-inlinemenu>
</div>