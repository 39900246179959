import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth.service';
import { BaseService } from 'src/app/core/base/base.service';
import { AuthenticationUtils } from './utils/auth-utils';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    AuthService,
    BaseService,
    AuthenticationUtils
  ]
})
export class AuthModule { }
