<div *ngIf="loading">
    <div class="p-fl-container"></div>
    <div class="flex p-fl-spinner">
        <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
    </div>
</div>

<div class="block sm:flex m-0 pt-2 px-0 col-12 md:align-items-center">
    <div class="main-title m-0 p-0 text-5xl md:text-6xl font-bold col-12 text-center sm:text-left sm:col-7">
        {{ "Nuovo rimborso" | translate }}
    </div>
    <div class="col-12 sm:col-5 mt-3 sm:mt-0 p-0 flex align-items-center justify-content-center sm:justify-content-end">
        <div class="oris-button oris-yellow col-6 px-1 sm:col-7 lg:col-4 ml-3" routerLink="/">
            <i class="pi pi-arrow-left mr-1"></i>
            {{ "Indietro" | translate }}
        </div>
    </div>
</div>
<div class="layout-light block lg:flex col-12 px-0">
    <form class="block col-12 p-0" (ngSubmit)="onSubmit(requestForm.form.valid)" #requestForm="ngForm">
        <div class="col-12 block lg:flex m-0 p-0">
            <div class="col-12 lg:col-6 p-fluid flex pl-0 pr-0 lg:pr-3 py-0 mt-1">
                <div class="form-container col-12 p-0">
                    <div class="mb-4" *ngIf="submitError">
                        <p-message severity="error" text="{{ submitErrorMessage | translate }}"></p-message>
                    </div>
                    <span class="p-input-icon-left mb-4">
                        <i class="pi pi-book"></i>
                        <input type="text" name="title" id="title" autocomplete="off" pInputText
                            placeholder="*{{ 'Titolo' | translate }}"
                            class="p-inputtext p-component p-element block mb-3" [(ngModel)]="title" required />
                    </span>
                    <span class="p-input-icon-left mb-4">
                        <i class="pi pi-list"></i>
                        <p-multiSelect [showClear]="true" name="categories"
                            class="category-select p-component p-element block mb-3" [options]="availableCategories"
                            [(ngModel)]="selectedCategories" [required]="true" optionLabel="label" optionValue="value"
                            placeholder="*{{ 'Categorie' | translate }}"></p-multiSelect>
                    </span>
                    <span class="p-input-icon-left mb-4">
                        <i class="pi pi-calendar"></i><p-calendar class="p-component p-element block mb-3" name="dates"
                            [(ngModel)]="requestDates" [required]="true" selectionMode="range" [readonlyInput]="true"
                            dateFormat="dd.mm.yy" placeholder="*{{ 'Data' | translate }}"></p-calendar>
                    </span>
                    <span class="p-input-icon-left mb-4">
                        <i class="pi pi-euro"></i><p-inputNumber class="p-component p-element block mb-3" name="amount"
                            [(ngModel)]="amount" [required]="true" mode="decimal" [minFractionDigits]="2"
                            [maxFractionDigits]="2" placeholder="*{{ 'Importo' | translate }} "></p-inputNumber>
                    </span>
                    <textarea rows="4" [autoResize]="true" name="notes" placeholder="{{ 'Note' | translate }}"
                        pInputTextarea [(ngModel)]="notes"></textarea>
                </div>
            </div>
            <div class="col-12 lg:col-6 mt-4 lg:mt-1 pl-0 pr-0 lg:pl-3 pb-0 fileUploadContainer">
                <p-fileUpload #uploadedFiles name="uploadedFiles[]" class="block h-full"
                    [ngClass]="invalidFile ? 'invalid' : ''" (uploadHandler)="onFileSelect($event)"
                    (onRemove)="onFileRemove($event)" [customUpload]="true" [multiple]="true" [auto]="true"
                    accept="image/*,application/pdf" [maxFileSize]="25000000" [previewWidth]="100"
                    [showUploadButton]="false" [showCancelButton]="false" chooseIcon="pi pi-paperclip"
                    removeStyleClass="square-40" chooseStyleClass="oris-yellow max-200"
                    chooseLabel="*{{ 'Allega file' | translate }}"></p-fileUpload>
            </div>
        </div>
        <div class="button-container col-12 mt-2 flex justify-content-center px-0 lg:justify-content-end">
            <button type="submit" pButton label="{{ 'Invia richiesta' | translate }}" class="block oris-button"
                style="max-width: 300px"></button>
        </div>
    </form>
</div>