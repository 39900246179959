import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../../app.component';
import { AuthService } from '../auth/auth.service';
import { AppLayoutComponent } from './app.layout.component';
import { InteractionType } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { LayoutService } from './layout.service';

@Component({
    selector: 'app-topbar',
    template: `
            <div class="layout-topbar">
            
                <div class="layout-topbar-left">
                    <a [routerLink]="['/']" class="logo">
                        <img [src]="'assets/layout/images/orisline-logo.png'">
                    </a>
                </div>

                <!-- <app-menu></app-menu> -->

                <div class="layout-topbar-right">
                    
                    <ul style="padding-inline-start: 20px !important;" class="layout-topbar-right-items">
                        
                        <!-- <p-menubar [model]="items"></p-menubar> -->
                        <p-button class="ml-logout" icon="pi pi-sign-out" (click)="logout()" title="Logout" styleClass="p-button-rounded"></p-button>
                        <p-dropdown class="p-dropdown-flag" [options]="items" (onChange)="onSelectedLangChange($event)" [(ngModel)]="selectedCountry" [style]="{'margin-top': '10px', 'padding-left':'5px'}" optionLabel="name" placeholder="">
                            <ng-template pTemplate="selectedItem">
                                <div class="flex align-items-center" *ngIf="selectedCountry">
                                    <img src="assets/demo/images/flag/flag_placeholder.png" [class]="'flag flag-' + selectedCountry.code.toLowerCase()" style="width: 18px"/>
                                    <div>{{ selectedCountry.name }}</div>
                                </div>
                            </ng-template>
                            <ng-template let-country pTemplate="item">
                                <div class="flex align-items-center">
                                    <img src="assets/demo/images/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" style="width: 18px"/>
                                    <div>{{ country.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                        <div class="mx-1 text-xl align-items-center hidden sm:flex">{{username}}</div>
                        <!-- <div class="wpb_text_column wpb_content_element ct-block">
                            <div class="wpb_wrapper">
                                <p><a class="contact" [routerLink]="['/login']">Logout</a></p>
                            </div>
                        </div>  -->
                    </ul>
                </div>
            </div>
        
    `
})
export class AppTopbarComponent implements OnInit {
    items: any[];
    selectedCountry: any;
    public username: string = '';

    constructor(public app: AppComponent,
        public appLayout: AppLayoutComponent,
        private translate: TranslateService,
        public authService: AuthService,
        public layoutService: LayoutService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalService: MsalService,
        private router: Router) {
    }

    ngOnInit(): void {
        console.log("onInit");
        this.layoutService.customEventEmitter.subscribe((eventData: any) => {
            this.manageEvent(eventData.message, eventData.data);
        });
        this.translate.addLangs(['it', 'en', 'es', 'pt']);
        this.translate.setDefaultLang('it');

        let browserLang = this.translate.getBrowserLang();
        browserLang = browserLang.match(/it|en|es|pt/) ? browserLang : 'it';

        if (browserLang == "it") {
            this.app.showAllisone = true;
        }
        else {
            this.app.showAllisone = false;
        }

        this.items = [
            { name: ' ', code: 'it', locale: 'it' },
            { name: ' ', code: 'gb', locale: 'en' },
            { name: ' ', code: 'pt', locale: 'pt' },
            { name: ' ', code: 'es', locale: 'es' },
            { name: ' ', code: 'de', locale: 'de' }
        ];

        this.selectedCountry = this.items.find(x => x.locale == browserLang);
    }

    manageEvent(message: string, data: any) {
        switch (message) {
            case "usernameSet":
                this.username = data as string;
                break;
        }
    }

    logout() {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            this.msalService.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else {
            this.msalService.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }
    onSelectedLangChange(event) {
        this.translate.use(event.value.locale);
        if (event.value.locale == "it") {
            this.app.showAllisone = true;
        }
        else {
            this.app.showAllisone = false;
        }
    }
}
