import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { of, pipe } from "rxjs";
import { catchError, finalize, retry, tap } from "rxjs/operators";
import { AuthService } from "../auth.service";
import { CustomerServices } from "../models/customer-services";

@Injectable()
export class AuthenticationUtils {
    private _router: Router;
    private _customerService: CustomerServices;
    constructor(
        private router: Router,
        private authService: AuthService
    ) {
        this._router = router;
    }

    public manageAuthorized(redirectToHome: boolean = false): void {
        let token = localStorage.getItem("tokenweb");
        if (token == null) {
            this.manageUnauthorized();
        }
        return;
    }

    public manageUnauthorized() {
        sessionStorage.removeItem("tokenweb");
        this._router.navigate(["/login"]);
    }
}