import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {

    public customEventEmitter: EventEmitter<any> = new EventEmitter<any>();

    constructor() { }

}