import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestComponent } from './request.component';
import { CardModule } from 'primeng/card';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { RequestRoutingModule } from './request-routing.module';
import { FormsModule } from '@angular/forms';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { RequestService } from './service/request_service';

@NgModule({
    declarations: [RequestComponent],
    imports: [
        FormsModule,
        CommonModule,
        FileUploadModule,
        RequestRoutingModule,
        InputTextareaModule,
        ProgressSpinnerModule,
        MultiSelectModule,
        CalendarModule,
        CardModule,
        ButtonModule,
        MessagesModule,
        DropdownModule,
        MessageModule,
        InputNumberModule,
        TranslateModule.forChild(),
    ],
    providers: [RequestService],
})
export class RequestModule {}
