import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BearerTokensResponse } from './models/response/bearer-tokens';
import { BaseRequestMethod, BaseService } from 'src/app/core/base/base.service';
import { CustomerServices } from './models/customer-services';

@Injectable()
export class AuthService {

    public customerId: string;
    public customerServices: CustomerServices = {};

    constructor(private http: HttpClient, private baseService: BaseService) { }

    azureLogin() {
        return this.http.get<any>(`https://0.0.0.0:5012/refund/azureLogin`);
    }

    getJWTToken() {
        return localStorage.getItem('tokenweb');
    }

    setJWTToken(jwtToken: string) {
        if (!localStorage.getItem('tokenweb'))
            localStorage.setItem('tokenweb', jwtToken);
    }

    removeJWTToken() {
        if (localStorage.getItem('tokenweb'))
            localStorage.removeItem('tokenweb');
    }

    getCustomerUsername() {
        return localStorage.getItem('username');
    }

    setCustomerUsername(username: string) {
        if (!localStorage.getItem('username'))
            localStorage.setItem('username', username);
    }

    removeCustomerUsername() {
        if (localStorage.getItem('username'))
            localStorage.removeItem('username');
    }

    getCustomerId() {
        return localStorage.getItem('customerid');
    }

    setCustomerId(id: string) {
        if (!localStorage.getItem('customerid'))
            localStorage.setItem('customerid', id);
    }

    removeCustomerId() {
        if (localStorage.getItem('customerid'))
            localStorage.removeItem('customerid');
    }

    removeCustomerServices() {
        if (localStorage.getItem('customer-services'))
            localStorage.removeItem('customer-services');
    }
}
