import 'src/app/core/base/prototype/string';
import * as appConfigurations from 'src/app/core/config/config.json';

export class AppGlobals {
    static _isInitialized: boolean = false;
    static _params: any;

    static getAppConfigurations(): any {
        if (typeof (appConfigurations as any).default != 'undefined') {
            return (appConfigurations as any).default;
        }
        return appConfigurations;
    }

    static initialize(): void {
        if (!this._isInitialized) {
            this._isInitialized = true;
        }
    }

    static parseDefaultPathKeywordDefinitions(keyword: string): string {
        var keywords = {
            '@app': '/app',
            '@modules': '/app/modules',
        };

        if (typeof keywords[keyword] != 'undefined') {
            return keywords[keyword];
        }

        return null;
    }

    static setParam(key: string, value: any): void {
        if (typeof this._params == 'undefined') {
            this._params = {};
        }

        var parseTemplateKeyParam = (value: any) => {
            var parseModulesKeyParam = (
                modulesBasePath: string,
                templateBasePath: string,
                value: any
            ) => {
                var recursor = (obj: any) => {
                    for (var k in obj) {
                        if (
                            (typeof obj[k]).toLowerCase() == 'object' &&
                            obj[k] !== null
                        ) {
                            recursor(obj[k]);
                        } else {
                            if ((typeof obj[k]).toLowerCase() == 'string') {
                                obj[k] = this.parsePathMappingProperty(obj[k]);
                            }
                        }

                        if (k.toLowerCase() == 'views') {
                            var path = '/modules/' + obj.name + '/view/';
                            for (var view in obj[k]) {
                                var viewPath = path + view + '/';
                                Object.keys(obj[k][view]).forEach(
                                    (viewProperty) => {
                                        if (
                                            viewProperty == 'template' &&
                                            (typeof obj[k][view]
                                                .template).toLowerCase() ==
                                                'object'
                                        ) {
                                            if (
                                                obj[k][view].template
                                                    .templated === true
                                            ) {
                                                obj[k][view].template.item =
                                                    templateBasePath +
                                                    viewPath +
                                                    obj[k][view].template.item;
                                            } else {
                                                obj[k][view].template.item =
                                                    modulesBasePath +
                                                    viewPath +
                                                    obj[k][view].template.item;
                                            }
                                        } else if (
                                            viewProperty == 'template' &&
                                            (typeof obj[k][view]
                                                .template).toLowerCase() ==
                                                'string'
                                        ) {
                                            obj[k][view].template =
                                                modulesBasePath +
                                                viewPath +
                                                obj[k][view].template;
                                        } else if (viewProperty == 'styles') {
                                            for (
                                                var i = 0;
                                                i < obj[k][view].styles.length;
                                                i++
                                            ) {
                                                if (
                                                    (typeof obj[k][view].styles[
                                                        i
                                                    ]).toLowerCase() == 'object'
                                                ) {
                                                    if (
                                                        obj[k][view].styles[i]
                                                            .templated === true
                                                    ) {
                                                        obj[k][view].styles[
                                                            i
                                                        ].item =
                                                            templateBasePath +
                                                            viewPath +
                                                            obj[k][view].styles[
                                                                i
                                                            ].item;
                                                    } else {
                                                        obj[k][view].styles[
                                                            i
                                                        ].item =
                                                            modulesBasePath +
                                                            viewPath +
                                                            obj[k][view].styles[
                                                                i
                                                            ].item;
                                                    }
                                                } else {
                                                    obj[k][view].styles[i] =
                                                        modulesBasePath +
                                                        viewPath +
                                                        obj[k][view].styles[i];
                                                }
                                            }
                                        }
                                    }
                                );
                            }
                        }
                    }
                    return obj;
                };

                var modules = [];
                for (var module of value) {
                    modules.push(recursor(module));
                }
                return modules;
            };

            if ((typeof value).toLowerCase() == 'object') {
                Object.keys(value).forEach((property) => {
                    if (
                        (typeof value[property]).toLowerCase() == 'object' &&
                        typeof value[property].resolve != 'undefined' &&
                        typeof value[property].value != 'undefined' &&
                        value[property].resolve == true
                    ) {
                        value[property] = this.parsePathMappingProperty(
                            value[property].value
                        );
                    }
                });
            }

            if (typeof value.modules != 'undefined') {
                value.modules = parseModulesKeyParam(
                    value.modulesBasePath,
                    value.templateBasePath,
                    value.modules
                );
            }

            return value;
        };

        switch (key.toLowerCase()) {
            case 'template':
                this.assignPropetyValue(
                    this._params,
                    key,
                    parseTemplateKeyParam(value)
                );
                break;
            default:
                this.assignPropetyValue(this._params, key, value);
                break;
        }
    }

    static getParam(key: string) {
        if (typeof this._params != 'undefined') {
            return this.getObjectPropertyValue(this._params, key);
        }
        return null;
    }

    static parsePathMappingProperty(value: string) {
        if ((typeof value).toLowerCase() == 'string') {
            var matches = value.match(/\{(.*)\}/g);
            for (var i = 0; i < matches.length; i++) {
                var keyword = this.parseDefaultPathKeywordDefinitions(
                    matches[i.toString()]
                );
                if (keyword != null) {
                    var key = matches[i.toString()];
                    value = value.replace('{' + key + '}', keyword);
                }
            }
        }

        return value;
    }

    static assignPropetyValue(obj, prop, value) {
        if ((typeof prop).toLowerCase() == 'string') {
            prop = prop.split('.');
        }

        if (prop.length > 1) {
            let e = prop.shift();
            this.assignPropetyValue(
                (obj[e] =
                    Object.prototype.toString.call(obj[e]) === '[object Object]'
                        ? obj[e]
                        : {}),
                prop,
                value
            );
        } else {
            obj[prop[0]] = value;
        }
    }

    static getObjectPropertyValue(obj, prop) {
        if ((typeof prop).toLowerCase() == 'string') {
            prop = prop.split('.');
        }

        if (prop != null && prop.length > 1) {
            let e = prop.shift();
            return this.getObjectPropertyValue(
                (obj[e] =
                    Object.prototype.toString.call(obj[e]) === '[object Object]'
                        ? obj[e]
                        : {}),
                prop
            );
        } else {
            return obj[prop[0]];
        }
    }

    static userHasPermission(permissionCode: string): boolean {
        var result = false;
        var permissions = atob(sessionStorage.getItem('sdiLink_Permissions'));
        var permissionsList = permissions.split(',');
        if (
            permissionsList != null &&
            typeof permissionsList != 'undefined' &&
            permissionsList != null
        ) {
            for (var i = 0; i < permissionsList.length; i++) {
                if (permissionsList[i] === permissionCode) {
                    result = true;
                    break;
                }
            }
        }
        return result;
    }

    static userHasPermissions(permissionCodes: string[]): boolean {
        var result = false;

        for (var i = 0; i < permissionCodes.length; i++) {
            result = this.userHasPermission(permissionCodes[i]);
            if (result) break;
        }

        return result;
    }

    static storage() {
        return {
            get: (key: string) => {
                var item = sessionStorage.getItem(key);
                if (typeof item != 'undefined' && item != null) {
                    var result = JSON.parse(item);
                    return result.encoded == true
                        ? JSON.parse(result.value)
                        : result.value;
                }
                return null;
            },
            set: (key: string, value: any) => {
                var item = { encoded: false, value: value };

                if ((typeof value).toLowerCase() != 'string') {
                    item.encoded = true;
                    item.value = JSON.stringify(value);
                }

                sessionStorage.setItem(key, JSON.stringify(item));
            },
            remove: (key: string) => {
                var item = sessionStorage.getItem(key);
                if (typeof item != 'undefined' && item != null) {
                    sessionStorage.removeItem(key);
                }
            },
        };
    }
}
