import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { LoginData } from './models/request/login-data';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  public loginData = {} as LoginData;
  public finished: boolean = true;
  public loginErrorMessage: string = "";
  public loginError: boolean = false;
  public queryString: string;

  constructor(
    public app: AppComponent,
    private router: Router,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService
  ) {
    this.checkAndSetActiveAccount();
  }

  async ngOnInit(): Promise<void> {
    //this.isIframe = window !== window.parent && !window.opener;
    //await this.msalService.initialize();

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account);
        this.setLoginDisplay();
      });
    this.setLoginDisplay();
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
    this.router.navigate(['/home']);

  }

  async getToken(): Promise<string> {
    return this.msalService.instance.acquireTokenSilent({
      scopes: ['openid', 'profile', 'user.read'],
    })
      .then((response) => {
        return response.accessToken;
      })
      .catch((error) => {
        console.error('Error acquiring token:', error);
        return null;
      });
  }

  setLoginDisplay() {
    let accounts = this.msalService.instance.getAllAccounts();
    this.loginDisplay = accounts.length > 0;
  }

  login() {
    this.checkAndSetActiveAccount();
    this.msalService.instance
      .handleRedirectPromise()
      .then((tokenResponse) => {
        if (!tokenResponse) {
          const accounts = this.msalService.instance.getAllAccounts();
          if (accounts.length === 0) {
            // No user signed in
            this.msalService.instance.loginRedirect();
          }
        } else {
          // Do something with the tokenResponse
        }
      })
      .catch((err) => {
        // Handle error
        console.error(err);
      });
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      } else {
        this.msalService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest)
          .subscribe((response: any) => {
            this.msalService.instance.setActiveAccount(response.account);
          });;
      } else {
        this.msalService.loginRedirect();
      }
    }
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.msalService.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/"
      });
    } else {
      this.msalService.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  async onLogin() {

    this.finished = true;
  }
}
